export default {
  AUTOCOMPLETE_PRODUCT_TITLE_CLASS: 'product-title',
  BUTTON_PRIMARY_CLASS: 'button-primary',
  BUTTON_SECONDARY_CLASS: 'button-secondary',
  PRODUCT_REPEATER_CLASS: 'productgrid--items products-per-row-3',
  SEARCH_PAGE_CLASS: 'productgrid--outer layout--no-sidebar productgrid-gridview',
  VERIFY_FITMENT_TITLE: 'SELECT YOUR VEHICLE',
  FACET_DIALOG_TITLE: 'Filter Options',
  SELECT_CLASS: '',

  SEARCH_BOX_PLACEHOLDER: 'What are you looking for?',
  SEARCH_BOX_CLASS: 'live-search live-search-form form-fields-inline',
  SEARCH_BOX_FORM_CLASS: 'form-field no-label',
  SEARCH_BOX_INPUT_CLASS: 'form-field-input live-search-form-field',
  SEARCH_BOX_BUTTON_CLASS: 'live-search-button button-primary',
  SEARCH_BOX_DIALOG_OPENING_ICON_CLASS: 'site-header-mobile-search-button--button',
};
