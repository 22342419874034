const baseFitmentSearchFields = ['Year', 'Make', 'Model', 'Bed'];
const extraFitmentSearchFields = [];

function responseHandler(response) {
  const { Actions: [{ Type, RedirectUrl: url } = {}] = [] } = response;

  const { pathname } = window.location;

  if (!pathname.includes('/products/') && Type === 'Redirect' && url && pathname !== url) {
    window.location.assign(url);
    return;
  }

  return response;
}

function enableCategorySearchPage() {
  const { pageType } = window.ShopifyAnalytics.meta.page;
  if (
    (pageType === 'collection' && window.document.body.id !== 'hot-items') ||
    pageType === 'vendors' ||
    pageType === 'types'
  ) {
    window.document.body.classList.add('cm_enable-search');
  }
}

export default {
  platform: 'shopify',
  InitFunc: enableCategorySearchPage,
  responseHandler,
  SearchRequestDefaults: {
    pageSize: 36,
  },
  product: {
    // noImageSrc: 'https://cdn.shopify.com/s/files/1/0323/6898/4195/products/87182a0c970496e37e7de6763a43fc03_x300.jpg',
  },
  fitmentSearch: {
    baseFields: baseFitmentSearchFields,
    extraFields: extraFitmentSearchFields,
    expectResponseRedirect: true,
    hideVehicleFromUrl: true,
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: {
        replace: '.cm_enable-search.template-collection .productgrid--utils',
        class: 'search-page-category',
      },
      template: 'SearchPage',
    },
    {
      name: 'SingleVehicleGarage',
      location: { firstChildOf: '.site-header-actions', class: 'cm_single-vehicle-garage right cm_desktop' },
      template: 'fitmentSearch/singleVehicleGarage',
    },
    {
      name: 'MobileSingleVehicleGarage',
      type: 'SingleVehicleGarage',
      location: {
        lastChildOf: '.site-header-main-content',
        class: 'cm_single-vehicle-garage small-promo cm_mobile cm_desktop-hide',
      },
      template: 'fitmentSearch/singleVehicleGarage',
    },
    {
      name: 'HomeVehicleWidget',
      type: 'VehicleWidget',
      location: '#cm_ymm-home',
      template: 'fitmentSearch/homeVehicleWidget',
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'CategoryVehicleWidget',
      type: 'VehicleWidget',
      location: { insertAfter: '.cm_enable-search.template-collection .breadcrumbs-container' },
      template: 'fitmentSearch/homeVehicleWidget',
    },
    {
      name: 'DialogVehicleWidget',
      type: 'VehicleWidget',
      useNativeDropdown: false,
    },
    {
      name: 'VerifyFitment',
      type: 'VehicleWidget',
      location: '.template-product .product-verify-fitment',
      template: 'fitmentSearch/verifyFitment',
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'review_score:desc': 'Customer Review',
        'title': 'A to Z',
        'title:desc': 'Z to A',
        'price': 'Price: Ascending',
        'price:desc': 'Price: Descending',
      },
    },
    {
      name: 'SearchResult',
    },
    {
      name: 'FacetPanel',
    },
    {
      name: 'SearchBoxDialogButton',
      location: { replace: '.site-header-main .live-search', class: 'live-search' },
      template: 'searchBox/dialogButtonAdaptable',
    },
    {
      name: 'facet-dialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/dialog',
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'vehicle-dialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/dialog',
    },
  ],
};
